/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  function stickyFooter () {
    var windowHeight = $(window).height();
    //var bodyHeight = $('body').outerHeight();
    var bodyHeight = $('header.banner').outerHeight() + $('.menu-dashboard').outerHeight() + $('.defileParent').outerHeight() + $('#tab4').outerHeight() + $('.partners').outerHeight() + $('footer').outerHeight()
    if (windowHeight > bodyHeight) {
      var hauteurMain = windowHeight - $('footer').outerHeight() - $('.partners').outerHeight() - $('.menu-dashboard').outerHeight() - $('.defileParent').outerHeight()
      $('main').css('min-height', hauteurMain + 'px')
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        $('#shareIcons').jsSocials({
                                     shares: ['facebook', 'twitter', 'linkedin', 'email'],
                                     shareIn: 'popup',
                                     showLabel: false,
                                     showCount: false
                                   })
        // JavaScript to be fired on all pages
        $('header.banner nav').stick_in_parent({ parent: 'body' })

        $('.input-search-formation').click(function () {
          $(this).removeAttr('placeholder')
        })

        $('.input-search-formation').focusout(function () {
          $(this).attr('placeholder', 'Rechercher une formation…')
        })

        $('.dropdown-toggle').click(function () {
          if ($(window).width() >= 1200) {
            window.location.assign($(this).attr('href'))
          }
        })

        if (window.location.hash) { //smooth scroll pour les liens externes (au chargement de la page)

          if ($(window.location.hash).length) {
            $('html, body').animate({
                                      //scrollTop: $(window.location.hash).offset().top - $('header > .navbar').height()
                                      scrollTop: $(window.location.hash).offset().top - $('header.banner').height()
                                    }, 750)
          }
        }

      },
      finalize: function () {
        $('.arrow-white-down').on('click', function () {
          if ($(window).width() >= 768) {
            $('html, body').animate({ scrollTop: $('.fond-turquoise-img').offset().top + $('.fond-turquoise-img').height() - $('.navbar-default').height() }, 1000)
          } else {
            $('html, body').animate({ scrollTop: $('.fond-turquoise-img').offset().top + $('.fond-turquoise-img').height() }, 1000)
          }
        })

        if ($('.fullheight').length) {
          var windowH = $(window).outerHeight()
          var wrapperH = $('.fullheight').offset().top
          var differenceH = windowH - wrapperH
          if (windowH > $('.fullheight').height()) {
            $('.fullheight').css({ 'min-height': (differenceH) + 'px' })
          }
        }

        $(window).resize(function () {
          var windowH = $(window).innerHeight()
          var wrapperH = $('.fullheight').offset().top
          var differenceH = windowH - wrapperH
          if (windowH > $('.fullheight').height()) {
            $('.fullheight').css('min-height', (differenceH) + 'px')
          }
        })

        //JS for open form

        $('.info-formation-button').click(function () {
          $('.info-formation-contact').css({
                                             'transform': 'translateY(0)',
                                             'opacity': '1',
                                             'visibility': 'visible',
                                             'margin-top': '5rem'
                                           })
          $('.info-formation').css('height', 'auto')
        })

        $('.matche-height').matchHeight()
        $('.matche-height-2').matchHeight()

        stickyFooter()

        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.chiffre-counter').appear(function () {
          var id = $(this).attr('id')
          var suffixe = $(this).attr('data-suffixe')
          var chiffre = $(this).attr('data-counter')
          var prefixe = $(this).attr('data-prefixe')
          var compteur = new CountUp(id, 0, chiffre, 0, 2.5, {
            separator: ' ',
            suffix: suffixe,
            prefix: prefixe
          })
          compteur.start()
        })

        $('.owl-logo').owlCarousel({
                                     nav: true,
                                     loop: true,
                                     margin: 70,
                                     autoWidth: true,
                                     items: 6,
                                     autoplay: true,
                                     smartSpeed: 1000,
                                     autoplayTimeout: 2000,
                                     navText: ['<i class=" fa fa-chevron-circle-right"></i>', '<i class=" fa fa-chevron-circle-left"></i>'],

                                     responsive: {
                                       0: {
                                         items: 1
                                       },
                                       600: {
                                         items: 3
                                       },
                                       1000: {
                                         items: 5
                                       }
                                     }
                                   })

                                   $('.owl-testimonials').owlCarousel({
                                     items: 1,
                                     nav: true,
                                     loop: true,
                                     autoplay: false,
                                     smartSpeed: 1000,
                                     autoplayTimeout: 5000,
                                     autoplayHoverPause: true,
                                     navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g fill="none" stroke="#ccc" stroke-width="1"><circle cx="20" cy="20" r="20" stroke="none"/><circle cx="20" cy="20" r="19.5" fill="none"/></g><g transform="translate(8 8)"><rect width="24" height="24" fill="#ccc" opacity="0"/><path d="M10.707,17.707,16.414,12,10.707,6.293,9.293,7.707,13.586,12,9.293,16.293Z" transform="translate(0)" fill="#ccc"/></g></svg>',
                                     '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g transform="translate(-308 -5073)"><g transform="translate(308 5073)" fill="none" stroke="#ccc" stroke-width="1"><circle cx="20" cy="20" r="20" stroke="none"/><circle cx="20" cy="20" r="19.5" fill="none"/></g><g transform="translate(316 5081)"><rect width="24" height="24" fill="#ccc" opacity="0"/><path d="M15,17.707,9.293,12,15,6.293l1.414,1.414L12.121,12l4.293,4.293Z" transform="translate(-1.707)" fill="#ccc"/></g></g></svg>'],
                                   })

                                   console.log('ok');
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        $('.stickyArrow').on('click', function () {
          var search = $('.section-home-recherche')

          if ($(window).width() >= 768) {
            $('html, body').animate({ scrollTop: search.offset().top + search.height() + $('.section-ligne').height() - $('.navbar-default').height() }, 1000)
          } else {
            $('html, body').animate({ scrollTop: search.offset().top + search.height() + $('.section-ligne').height() }, 1000)
          }
        })
      }
    },
    // About us page, note the change from about-us to about_us. tax-formations
    'catalogue_des_formations': {
      init: function () {
        // JavaScript to be fired on the about us page
        $('article .content').matchHeight({
                                            byRow: true
                                          })
      }
    },
    'tax_formations': {
      init: function () {
        // JavaScript to be fired on the about us page
        $('article .content').matchHeight({
                                            byRow: false
                                          })
      }
    },
    'search_results': {
      init: function () {
        // JavaScript to be fired on the about us page
        $('article').click(function () {
          var urlPage = $(this).find('a').attr('href')
          window.location = urlPage
        })
      }
    },
    'contact': {
      init: function () {

      },
      finalize: function () {
        $('#formation').select2({
                                  theme: 'bootstrap',
                                  placeholder: 'Veuillez sélectionner une formation',
                                  allowClear: true
                                });
      }
    },
    'votre_avis': {
      finalize: function () {

      }
    },'votre_avis_google': {
      finalize: function () {
        $('.btn-clipboard').on('click', function () {
          copyToClipboard();

          setTimeout(function () {
            $('.btn-clipboard').tooltip('disable');
          }, 2000);
        });

        function copyToClipboard () {
          $('.btn-clipboard').tooltip('enable');
          $('.btn-clipboard').tooltip('show');
          var $temp = $('<input>');
          $('body').append($temp);
          $temp.val($('#avis').text()).select();
          document.execCommand('copy');
          $temp.remove();
        }
      }
    }
  }

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire
      var namespace = Sage
      funcname = (funcname === undefined) ? 'init' : funcname
      fire = func !== ''
      fire = fire && namespace[func]
      fire = fire && typeof namespace[func][funcname] === 'function'

      if (fire) {
        namespace[func][funcname](args)
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common')

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm)
        UTIL.fire(classnm, 'finalize')
      })

      // Fire common finalize JS
      UTIL.fire('common', 'finalize')
    }
  }

  // Load Events
  $(document).ready(UTIL.loadEvents)

  const navbarCollapse = document.querySelector('#navbar');
  const menuTop = document.querySelector('.menu-top')
  
  if(window.matchMedia("(max-width: 600px)").matches) {
    navbarCollapse.append(menuTop);
    console.log('test ok');
  } 
  // else {
  //   navbarCollapse.removeChild(menuTop);
  // }
  // console.log(navbarCollapse);
  

  // page actu add active class to first filter nav item ! 
  const el = document.querySelector('.actu-filters-block .uk-subnav li:first-child');
  el.classList.add('uk-active');


})(jQuery) // Fully reference jQuery after this point.
